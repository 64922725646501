import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import Quantity from '../Quantity/Quantity';
import Price from '../Price/Price';
import RequiredProducts from '../RequiredProducts/RequiredProducts';
import DrawerProduct from '../Drawer/DrawerProduct';
import {
  putBasketItemRequest,
  postBasketItemRequest,
} from '../../redux/actions/basket.actions';
import { checkReduxResponse } from '../../services/httpService';
import {
  dispatchAddToCart,
  dispatchOpenDrawer,
  dispatchRemoveToCart,
  dispatchUpdateItem,
} from '../../services/tracking';
import { shopConfig } from '../../Globals/ShopConfig';
import Translator from '../../services/translator';
import SimplePrice from '../Price/SimplePrice';
import { currencyPrecision } from '../../Constants/Constants';
import './Basket.css';

class BasketItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newQuantity: 0,
      openModal: false,
    };
    this.timeout = '';
    this.newBasketItem = {
      itemId: null,
      quantity: null,
      project: shopConfig.project.name,
      pos: shopConfig.pos.slug,
    };
  }

  componentDidMount() {
    this.setState({
      itemId: this.props.basketItem.id,
      quantity: this.props.basketItem.quantity,
      project: shopConfig.project.name,
      pos: shopConfig.pos.slug,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentBasket !== this.props.currentBasket) {
      if (checkReduxResponse(this.props.currentBasket, 'basket')) {
        this.setState({ loadingItems: false, loadingDelete: false });
      }
    }

    if (prevProps.ticketDetails !== this.props.ticketDetails) {
      if (checkReduxResponse(this.props.ticketDetails, 'ticket')) {
        if (this.props.ticketDetails.ticket.product) {
          this.checkTicket(this.props.ticketDetails.ticket.product);
        }
      }
    }

    if (prevProps.isLoading !== this.props.isLoading) {
      if (!this.props.isLoading) {
        this.setState({ newQuantity: 0 });
      }
    }
  }

  checkTicket(product) {
    if (product.hasOptions || product.hasSessions) {
      this.setState({ openModal: true });
      dispatchOpenDrawer(product.name);
    }
    if (!product.hasSessions && !product.hasOptions) {
      this.props.postBasketItem({
        formuleId: product.id,
        quantity: product.quantityMinPerSale
          ? product.quantityMinPerSale
          : product.quantityIncrement,
        sessionId: null,
        project: shopConfig.project.name,
        pos: shopConfig.pos.slug,
      });
      dispatchAddToCart(product.name, product.price.amount);
    }
  }

  handleMin(basketItemId) {
    this.setState({ newQuantity: this.state.newQuantity - 1 });
    this.handleChangeQuantity(basketItemId);
  }

  handlePlus(basketItemId) {
    this.setState({ newQuantity: this.state.newQuantity + 1 });
    this.handleChangeQuantity(basketItemId);
  }

  handleChangeQuantity(basketItemId) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.setLoadingBasket(true);
      this.newBasketItem.quantity =
        this.props.basketItem.quantity + this.state.newQuantity;
      this.newBasketItem.itemId = basketItemId;
      this.props.updateBasket(this.newBasketItem);
      dispatchUpdateItem(
        this.props.basketItem.name,
        this.newBasketItem.quantity
      );
    }, 500);
  }

  handleDeleteItem(basketItem) {
    this.props.setLoadingBasket(true);
    this.newBasketItem.quantity = 0;
    this.newBasketItem.itemId = basketItem.id;
    this.props.updateBasket(this.newBasketItem);

    dispatchRemoveToCart(basketItem.name, basketItem.price);
  }

  render() {
    const { basketItem, product, editable } = this.props;
    const { openModal } = this.state;

    return (
      <>
        {!basketItem.isLocked && (
          <div className="basketItemContainer">
            <div className="basketItemContainerName">
              {editable && (
                <div className="deleteIcon">
                  <DeleteOutlined
                    style={{ color: 'red' }}
                    onClick={() => this.handleDeleteItem(basketItem)}
                  />
                  {basketItem.formuleRelationRequired &&
                    basketItem.formuleRelationRequired.length > 0 && (
                      <RequiredProducts
                        classIcon="warning-item-basket-button"
                        products={basketItem.formuleRelationRequired}
                        disabledActions
                      />
                    )}
                </div>
              )}
              <div className="basketItemName">
                {!editable && <span>{basketItem.quantity}&times; </span>}
                <span>{basketItem.name}</span>
                {basketItem.sessionTxt && (
                  <ul>
                    {basketItem.sessionTxt.split(', ').map((data, i) => (
                      <li key={i}>{data}</li>
                    ))}
                    {basketItem.placementInfos &&
                      basketItem.placementInfos.map((data, i) => (
                        <li key={i}>
                          {Translator.trans('ticket.placement', {
                            seat: data.unitKey,
                          })}
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="basketItemContainerQtty">
              <div className="basket-item">
                {editable
                  ? !basketItem.placementInfos && (
                      <Quantity
                        minQuantity={basketItem.formuleMinQty}
                        quantityMaxPerSale={basketItem.formuleMaxQty}
                        handleMin={() => this.handleMin(basketItem.id)}
                        currentValue={
                          basketItem.quantity + this.state.newQuantity
                        }
                        handlePlus={() => this.handlePlus(basketItem.id)}
                      />
                    )
                  : basketItem.priceUnit >= currencyPrecision && (
                      <div className={'recap-unit-price-container'}>
                        <SimplePrice
                          price={basketItem.priceUnit}
                          currency={shopConfig.locale.currencyCode}
                        />
                      </div>
                    )}
                {basketItem.isDiscount ? (
                  <Price
                    priceDiscount={basketItem.price}
                    currentPrice={basketItem.priceBrutWithVat}
                    currency={shopConfig.locale.currencyCode}
                  />
                ) : (
                  <Price
                    currentPrice={basketItem.price}
                    currency={shopConfig.locale.currencyCode}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {basketItem.isLocked && !basketItem.isFees && (
          <div className="basketItemContainer">
            <div className="basketItemContainerName">
              <div className="basketItemName">
                <span>{basketItem.quantity}&times; </span>
                <span>{basketItem.name}</span>
              </div>
            </div>
            <div className="basketItemContainerQtty">
              <div className="basket-item">
                <Price
                  currentPrice={basketItem.price}
                  currency={shopConfig.locale.currencyCode}
                />
              </div>
            </div>
          </div>
        )}
        {openModal && (
          <DrawerProduct
            isOpen={openModal}
            product={product}
            onCancel={() => {
              this.setState({ openModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentBasket: state.basket.currentBasket,
  ticketDetails: state.tickets.ticketDetails,
});

const mapDispatchToProps = (dispatch) => ({
  updateBasket: (updatedItem) => dispatch(putBasketItemRequest(updatedItem)),
  postBasketItem: (newBasket) => dispatch(postBasketItemRequest(newBasket)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasketItem);
